export const actionCodes = {
    create: 'customer_create_p',
    delete: 'customer_delete_p',
    read: 'customer_read_p',
    update: 'customer_update_p',
    view_all: 'customer_view_all',
}

export const appNames = {
    CONTROLAPP: 'CONTROLAPP',
    EVALUATIONS: 'EVALUATIONS',
    SAREB_SERVICERS: 'SAREB_SERVICERS',
    TRACEAPP: 'TRACEAPP',
    VISITOR_CONTROL: 'VISITOR_CONTROL',
    WEBCONTROL: 'WEBCONTROL',
}

export const toolCodes = {
    discontinuousSurveillanceServices: 'DISCONTINUOUS_SURVEILLANCE',
    evaluations: 'EVALUATION_REPORTS',
    events: 'EVENTS',
    home: 'TO DO',
    incidents: 'INCIDENTS',
    keyrings: 'KEYRINGS',
    registrationRequests: 'REGISTRATION_REQUESTS',
    rounds: 'ROUNDS',
    surveillanceServices: 'SURVEILLANCE',
    visitorControl: 'VISITOR_LIST',
    visits: 'VISITS',
}

export const viewNames = {
    DISCONTINUOUS_SURVEILLANCE: 'DISCONTINUOUS_SURVEILLANCE',
    EVALUATIONS: 'EVALUATIONS',
    EVENTS: 'EVENTS',
    HOME: 'HOME',
    INCIDENTS: 'INCIDENTS',
    KEYRINGS: 'KEYRINGS',
    REGISTRATION_REQUEST_FORM: 'REGISTRATION_REQUEST_FORM',
    REGISTRATION_REQUEST_SPREADSHEET_FORM: 'REGISTRATION_REQUEST_SPREADSHEET_FORM',
    ROUNDS: 'ROUNDS',
    SURVEILLANCE: 'SURVEILLANCE',
    VISITOR_CONTROL: 'VISITOR_CONTROL',
    VISITS: 'VISITS',
}

export const viewNamesEs = {
    discontinuousSurveillanceServices: 'Vigilancia discontinua',
    evaluations: 'Inspecciones',
    events: 'Eventos de alarma',
    home: 'Inicio',
    incidents: 'Acudas',
    keyrings: 'Gestión de llaves',
    registrationRequestForm: 'Alta de alarma',
    registrationRequestUpload: 'Alta desde archivo',
    rounds: 'Rondas',
    sarebRequests: 'Solicitudes Sareb',
    surveillanceServices: 'Informes de servicio',
    visitorControl: 'Control de visitas',
    visits: 'Acompañamientos',
    webcontrol: 'Gestión de alarmas',
}

export const viewNamesEn = {
    discontinuousSurveillanceServices: 'Discontinuous surveillance',
    evaluations: 'Inspections',
    events: 'Events',
    home: 'Home',
    incidents: 'Incident responses',
    keyrings: 'Keyring management',
    registrationRequestForm: 'Alarm registration',
    registrationRequestUpload: 'Register from file',
    rounds: 'Rounds',
    sarebRequests: 'Sareb requests',
    surveillanceServices: 'Surveillance',
    visitorControl: 'Visitor control',
    visits: 'Visits',
    webcontrol: 'Alarm management',
}

export const translationsEs = {
    accessedInterior: 'Acceso al interior',
    actions: 'Acciones',
    activatedDevice: 'Aparato activado',
    address: 'Dirección',
    arrived_date: 'Fecha de llegada',
    assetId: 'Id activo',
    asteriskNote: 'Campos marcado con * son obligatorio',
    boxNfcTag: 'Etiqueta NFC caja',
    building: 'Edificio',
    cameraScreen: 'Vista cámara',
    cardNumber: 'Nº tarjeta',
    center: 'Delegación',
    checkpoint: 'Punto',
    checkpointDescription: 'Descripción del punto',
    checkpoints: 'Puntos',
    city: 'Localidad',
    common: 'Común',
    company: 'Empresa',
    companyName: 'Nombre empresa',
    compartment: 'Compartimento',
    complete: 'Completo',
    contract: 'Contrato',
    contractData: 'Datos contrato',
    creationDate: 'Fecha de creación',
    creationTime: 'Hora de creación',
    customer: 'Cliente',
    customerInformation: 'Información cliente',
    customerPortal: 'Portal del cliente',
    date: 'Fecha',
    dateFormat: 'dd/MM/yyyy',
    dateTimeFormat: 'dd/MM/yyyy HH:mm',
    description: 'Descripción',
    details: 'Detalles',
    dispatchedPolice: 'Policía enviado',
    dispatchedWatchman: 'Acuda enviado',
    downloadPdf: 'Descargar PDF',
    downloadExcel: 'Descargar Excel',
    downloadServicerManual: 'Descargar manual de servicers',
    downloadUserManual: 'Descargar manual de usuarios',
    duringBusinessHours: 'De 09:00 a 21:00',
    endDate: 'Fecha fin',
    english: 'Inglés',
    errorAddress: 'La dirección es obligatoria',
    errorAssetId: 'El id activo es obligatorio',
    errorCcaa: 'La comunidad autónoma es obligatoria',
    errorCity: 'La población es obligatoria',
    errorConfiguration: 'Error obteniendo configuración',
    errorCp: 'El código postal es obligatorio',
    errorDownloadEvaluationReport: 'Error descargando informe de la inspección',
    errorDownloadExcel: 'Error descargando Excel',
    errorDownloadImage: 'Error descargando Imagen',
    errorDownloadIncidentReport: 'Error descargando informe de la incidencia',
    errorDownloadPdf: 'Error descargando PDF',
    errorDownloadVisitReport: 'Error descargando informe del acompañamiento',
    errorDropdown: 'Error recogiendo datos de los menus desplegable',
    errorFetchCities: 'Error obteniendo listado de poblaciones',
    errorFetchInstallationsByCustomer: 'Error obteniendo listado de instalaciones por cliente',
    errorFetchProvinces: 'Error obteniendo listado de provincias',
    errorGeneral: 'Error general',
    errorIncidentData: 'Error obteniendo datos de acuda',
    errorLanguageSwitch: 'Error cambiando idioma',
    errorLaunchDateAfter: 'La fecha de actuación debe ser en el futuro',
    errorLaunchDateDate: 'La fecha de actuación tiene que ser de tipo fecha',
    errorLaunchDateRequired: "Cuando se selecciona tipo 'lanzamiento', la fecha de actuación es obligatoria",
    errorLogin: 'Usuario / Contraseña incorrecto',
    errorMaxDate: 'La fecha fin no puede ser más de un mes después de la fecha inicio',
    errorMinDate: 'La fecha fin no puede ser anterior a la fecha de inicio',
    errorPermissionsView: 'No tienes permiso para entrar a la sección',
    errorPromotionCode: 'El campo código promoción tiene un máximo de 255 carácteres',
    errorProvince: 'La provincia es obligatoria',
    errorRefCatastral: 'La referencia catastral es obligatoria',
    errorRefreshConfiguration: 'Error actualizando configuración',
    errorRegistrationRequestSpreadsheet: 'Se requiere un archivo XLSX válido',
    errorRequestType: 'Tipo de solicitud inválida',
    errorServicerCode: 'Servicer inválido',
    errorServicerWithoutAccess: 'El servicer seleccionado no tiene acceso a la funcionalidad',
    errorSessionExpired: 'La sesión ha expirado. Inicia sesión e inténtalo de nuevo.',
    errorSpreadsheetEmpty: 'La hoja de cálculo cargada está vacía',
    errorSpreadsheetHeaders: 'El archivo XLSX seleccionado tiene cabeceras incorrectas',
    errorSpreadsheetSheetname: 'El archivo XLSX seleccionado tiene un nombre de hoja incorrecto',
    errorSubmitRegistrationRequestForm: 'Error cargando solicitud de alta',
    errorTableData: 'Error obteniendo datos de la tabla',
    errorUploadRegistrationRequestSpreadsheet: 'Error subiendo hoja de cálculo de solicitud de alta',
    errorVisitData: 'Error obteniendo datos de acompañamiento',
    event: 'Evento',
    eventData: 'Datos del Evento',
    eventGroups: {
        ATAR: 'Fallo de corriente',
        BABR: 'Alarma de robo',
        BBBU: 'Anulación de zona',
        HA: 'Alarma de coacción',
        OPCL: 'Apertura/Cierre',
        PA: 'Alarma de pánico',
        TA: 'Tamper',
    },
    events: {
        CL: 'Cierre',
        OP: 'Apertura',
    },
    extraordinary: 'extraordinaria',
    finished_date: 'Fecha de finalización',
    hideTitles: 'Esconder títulos',
    image: 'Imagen',
    images: 'Imágenes',
    incident_responses: 'Acuda',
    incident: 'Incidencia',
    incidentCause: 'Motivo de salto',
    incidentData: 'Datos de Acuda',
    incidentId: 'Id de Acuda',
    inspectionCode: 'Código de inspección',
    installationCode: 'Código de instalación',
    invalid: 'No válido',
    isIntrusion: 'Intrusión',
    isSystemVerified: 'Sistema de alarma verificado',
    keyringCustody: 'Custodia de llaves',
    keyringNfcTag: 'Etiqueta NFC llavero',
    keyringType: 'Tipo llavero',
    keysStatus: 'Estado llaves',
    launch: 'lanzamiento',
    launchDate: 'Fecha de actuación',
    loading: 'Cargando...',
    loadingIncidentData: 'Cargando datos de acuda',
    loadingVisitData: 'Cargando datos de acompañamiento',
    location: 'Localización',
    logout: 'Cerrar sesión',
    month: 'Mes',
    name: 'Nombre',
    newRegistrationRequest: 'Nueva solicitud de alta',
    next: 'Siguiente',
    noData: 'No hay datos disponibles',
    notAvailable: 'No disponible',
    noticeNumber: 'Número de aviso',
    numKeys: 'Llaves',
    observations: 'Observaciones',
    of: 'de',
    ordinary: 'ordinaria',
    page: 'Página',
    partition: 'Partición',
    password: 'Contraseña',
    perimeterVerificationOption: 'Verificación cerramiento/perímetro',
    perimeterVerificationText: 'Observaciones de verificación cerramiento/perímetro',
    photoGallery: 'Galería de Fotos',
    policeForceOption: 'Cuerpo Policial',
    previous: 'Anterior',
    private: 'Privado',
    promotionCode: 'Código promoción',
    property: 'Inmueble',
    propertyDamage: '¿Hay daños en el inmueble?',
    propertyData: 'Datos del Inmueble',
    province: 'Provincia',
    region: 'Comunidad autónoma',
    registerDate: 'Fecha de alta',
    registrationRequestUpload: 'Alta desde archivo',
    rememberMe: 'Recuérdame',
    requestType: 'Tipo solicitud',
    reset: 'Nueva solicitud',
    round: 'Ronda',
    rows: 'filas',
    sample: 'Muestra',
    sarebCode: 'Código de Sareb',
    sarebRegion: 'Región de Sareb',
    searchButtonText: 'Buscar',
    semiValid: 'Semi-válido',
    service: 'Servicio',
    serviceName: 'Nombre de servicio',
    servicerId: 'Id de servicer',
    show: 'Mostrar',
    showTitles: 'Mostrar títulos',
    signIn: 'Iniciar sesión',
    spanish: 'Español',
    startDate: 'Fecha inicial',
    submit: 'Enviar',
    subscriberCode: 'Código de abonado',
    success: 'Éxito',
    successSubmitRegistrationRequestForm: 'Solicitud de alta cargada correctamente',
    successUploadRegistrationRequestSpreadsheet: 'Hoja de cálculo cargada correctamente',
    surveillance: 'Vigilancia',
    surveillanceReport: 'Informe de Vigilancia',
    systemDamage: 'Daños sistema alarma',
    trackType: 'Tipo marcaje',
    transmitter: 'Transmisor',
    triggerCount: 'Saltos',
    triggerDate: 'Fecha de salto',
    triggerTime: 'Hora de salto',
    unspecified: 'Sin Especificar',
    upload: 'Subir',
    user: 'Usuario',
    userNameEmailAddress: 'Nombre usuario / Correo electrónico',
    valid: 'Válido',
    validation: {
        addressMax: 'El campo de dirección tiene un máximo de 191 caracteres',
        addressRequired: 'Indica una dirección',
        assetIdMax: 'El campo de Id de activo tiene un máximo de 191 caracteres',
        assetIdRequired: 'Indica un id de activo',
        cityRequired: 'Selecciona una localidad',
        launchDateMin: 'La fecha de actuación tiene que ser en el futuro',
        launchDateRequired: 'Indica una fecha de actuación para el lanzamiento',
        noticeNumberMax: 'El campo de número de aviso tiene un máximo de 191 caracteres',
        promotionCodeMax: 'El campo de código de promoción tiene un máximo de 191 caracteres',
        provinceRequired: 'Selecciona una provincia',
        refCatastralMax: 'El campo de referencia catastral tiene un máximo de 191 caracteres',
        refCatastralRequired: 'Indica una referencia catastral',
        requestTypeRequired: 'Selecciona un tipo de solicitud',
        servicerIdMax: 'El campo de id de servicer tiene un máximo de 191 caracteres',
        servicerRequired: 'Selecciona un servicer',
        spreadsheetFormat: 'El formato de fichero no es compatible, por favor compare con la muestra',
        spreadsheetRequired: 'Selecciona un fichero',
    },
    visitData: 'Datos de acompañamiento',
    visited: 'Visitado',
    visitMotive: 'Motivo visita',
    visitor: 'Visitante',
    visitorControl: 'Control de visitas',
    visitorList: 'Control de visitas',
    watchman: 'Vigilante',
    watchmanArrivalDate: 'Fecha de llegada',
    watchmanArrivalGeolocation: 'Geolocalización llegada',
    watchmanArrivalTime: 'Hora de llegada',
    watchmanCompletionDate: 'Fecha de fin',
    watchmanCompletionGeolocation: 'Geolocalización final',
    watchmanCompletionTime: 'Hora de fin',
    watchmanName: 'Vigilante',
    watchmanTip: 'Tip',
    yes: 'Sí',
    zone: 'Zona',
    tasks: 'Tareas',
    visit: 'Acompañamiento',
    roundIncidentDetails: 'Detalles incidencia punto de ronda #',
    loadingRoundIncidentImage: 'Cargando imagen de incidencia...',
    roundTasksDetails: 'Tarea(s) punto de ronda #',
    errorFetchingRoundTasks: 'Error cargando tareas del punto de ronda',
    loadingTasks: 'Cargando tareas...',
    pleaseTryAgain: 'Inténtelo de nuevo. Si el problema persiste, póngase en contacto con un responsable.',
    close: 'Cerrar',
    errorDownloadingIncidentImage: 'Error downloading incident image',
}

export const translationsEn = {
    accessedInterior: 'Accessed interior',
    actions: 'Actions',
    activatedDevice: 'Device activated',
    address: 'Address',
    arrived_date: 'Arrived date',
    assetId: 'Asset id',
    asteriskNote: 'Fields marked with * are obligatory',
    boxNfcTag: 'Box NFC tag',
    building: 'Building',
    cameraScreen: 'Camera view',
    cardNumber: 'Card number',
    center: 'Center',
    checkpoint: 'Checkpoint',
    checkpointDescription: 'Description',
    checkpoints: 'Checkpoints',
    city: 'City',
    common: 'Common',
    company: 'Company',
    companyName: 'Company name',
    compartment: 'Compartment',
    complete: 'Complete',
    contract: 'Contract',
    contractData: 'Contract Data',
    creationDate: 'Creation date',
    creationTime: 'Creation time',
    customer: 'Customer',
    customerInformation: 'Customer information',
    customerPortal: 'Customer Portal',
    date: 'Date',
    dateFormat: 'yyyy-MM-dd',
    dateTimeFormat: 'yyyy-MM-dd hh:mm',
    description: 'Description',
    details: 'Details',
    dispatchedPolice: 'Police dispatched',
    dispatchedWatchman: 'Watchman dispatched',
    downloadPdf: 'Download PDF',
    downloadExcel: 'Download Excel',
    downloadServicerManual: 'Download servicer manual',
    downloadUserManual: 'Download user manual',
    duringBusinessHours: 'Between 9:00 AM and 9:00 PM',
    endDate: 'End date',
    english: 'English',
    errorAddress: 'The address is required',
    errorAssetId: 'The asset id is required',
    errorCcaa: 'The autonomous region is required',
    errorCity: 'The city is required',
    errorConfiguration: 'Error retrieving configuration',
    errorCp: 'The zip code is required',
    errorDownloadEvaluationReport: 'Error downloading evaluation report',
    errorDownloadExcel: 'Error downloading Excel',
    errorDownloadImage: 'Error downloading Image',
    errorDownloadIncidentReport: 'Error downloading incident report',
    errorDownloadPdf: 'Error downloading PDF',
    errorDownloadVisitReport: 'Error downloading visit report',
    errorDropdown: 'Error retrieving dropdown data',
    errorFetchCities: 'Error retrieving list of cities',
    errorFetchInstallationsByCustomer: 'Error retrieving list of installations by customer',
    errorFetchProvinces: 'Error retrieving list of provinces',
    errorGeneral: 'General error',
    errorIncidentData: 'Error retrieving incident data',
    errorLanguageSwitch: 'Error changing language',
    errorLaunchDateAfter: 'The launch date has to be in the future',
    errorLaunchDateDate: 'Launch date has to be a valid date',
    errorLaunchDateRequired: "When type 'launch' is selected, a launch time is required",
    errorLogin: 'Wrong username / password',
    errorMaxDate: 'The end date cannot be more than one month after the start date',
    errorMinDate: 'The end date cannot be earlier than the start date',
    errorPermissionsView: "You don't have permission to enter the section",
    errorPromotionCode: 'The promotion code field has a maximum of 255 characters',
    errorProvince: 'The province is required',
    errorRefCatastral: 'The catastral ref is required',
    errorRefreshConfiguration: 'Error refreshing configuration',
    errorRegistrationRequestSpreadsheet: 'A valid XLSX file is required',
    errorRequestType: 'Invalid request type selected',
    errorServicerCode: 'Invalid servicer selected',
    errorServicerWithoutAccess: 'The selected servicer does not have access to the functionality',
    errorSessionExpired: 'The session has expired. Please sign in and try again.',
    errorSpreadsheetEmpty: 'The uploaded spreadsheet is empty',
    errorSpreadsheetHeaders: 'The selected XLSX file has the wrong headers',
    errorSpreadsheetSheetname: 'The selected XLSX file has the wrong sheet name',
    errorSubmitRegistrationRequestForm: 'Error submitting registration request form',
    errorTableData: 'Error retrieving table data',
    errorUploadRegistrationRequestSpreadsheet: 'Error uploading registration request spreadsheet',
    errorVisitData: 'Error retrieving visit data',
    event: 'Event',
    eventData: 'Event Data',
    eventGroups: {
        ATAR: 'Power failure',
        BABR: 'Burglar alarm',
        BBBU: 'Zone annulation',
        HA: 'Coercion alarm',
        OPCL: 'Opening/Closing',
        PA: 'Panic alarm',
        TA: 'Tamper',
    },
    events: {
        CL: 'Closing',
        OP: 'Opening',
    },
    extraordinary: 'extraordinary',
    finished_date: 'Finished date',
    hideTitles: 'Hide titles',
    image: 'Image',
    images: 'Images',
    incident_responses: 'Incident responses',
    incident: 'Incident',
    incidentCause: 'Trigger cause',
    incidentData: 'Data for incident',
    incidentId: 'Incident Id',
    inspectionCode: 'Inspection code',
    installationCode: 'Installation code',
    invalid: 'Invalid',
    isIntrusion: 'Intrusion',
    isSystemVerified: 'Alarm system verified',
    keyringCustody: 'Key custody',
    keyringNfcTag: 'Keyring NFC tag',
    keyringType: 'Keyring type',
    keysStatus: 'Keys status',
    launch: 'launch',
    launchDate: 'Launch date',
    loading: 'Loading...',
    loadingIncidentData: 'Loading incident data',
    loadingVisitData: 'Loading visit data',
    location: 'Location',
    logout: 'Log Out',
    month: 'Month',
    name: 'Name',
    newRegistrationRequest: 'New registration request',
    next: 'Next',
    noData: 'No data available',
    notAvailable: 'Not available',
    noticeNumber: 'Notice number',
    numKeys: 'Keys',
    observations: 'Observations',
    of: 'of',
    ordinary: 'ordinary',
    page: 'Page',
    partition: 'Partition',
    password: 'Password',
    perimeterVerificationOption: 'Verification lock/perimeter',
    perimeterVerificationText: 'Observations verification lock/perimeter',
    photoGallery: 'Photo Gallery',
    policeForceOption: 'Police force',
    previous: 'Previous',
    private: 'Private',
    promotionCode: 'Promotion Code',
    property: 'Property',
    propertyDamage: 'Property damaged',
    propertyData: 'Property Data',
    province: 'Province',
    region: 'Region',
    registerDate: 'Register date',
    registrationRequestUpload: 'Register from file',
    rememberMe: 'Remember me',
    requestType: 'Request type',
    reset: 'Reset',
    round: 'Round',
    rows: 'Rows',
    sample: 'Sample',
    sarebCode: 'Sareb code',
    sarebRegion: 'Sareb region',
    searchButtonText: 'Search',
    semiValid: 'Semi-valid',
    service: 'Service',
    serviceName: 'Service name',
    servicerId: 'Servicer Id',
    show: 'Show',
    showTitles: 'Show titles',
    signIn: 'Sign In',
    spanish: 'Spanish',
    startDate: 'Start date',
    submit: 'Submit',
    subscriberCode: 'Subscriber code',
    success: 'Success',
    successSubmitRegistrationRequestForm: 'Submission of registration request was successful',
    successUploadRegistrationRequestSpreadsheet: 'Spreadsheet uploaded successfully',
    surveillance: 'Surveillance',
    surveillanceReport: 'Surveillance Report',
    systemDamage: 'Alarm system damaged',
    trackType: 'Track type',
    transmitter: 'Transmitter',
    triggerCount: 'Triggers',
    triggerDate: 'Trigger date',
    triggerTime: 'Trigger time',
    unspecified: 'Unspecified',
    upload: 'Upload',
    user: 'User',
    userNameEmailAddress: 'User name / Email address',
    valid: 'Valid',
    validation: {
        addressMax: 'The address field has a maximum of 191 characters',
        addressRequired: 'Specify an address',
        assetIdMax: 'The asset id field has a maximum of 191 characters',
        assetIdRequired: 'Specify an asset id',
        cityRequired: 'Select a city',
        launchDateMin: 'The launch date must be in the future',
        launchDateRequired: 'Specify a launch date',
        noticeNumberMax: 'The notice number field has a maximum of 191 characters',
        promotionCodeMax: 'The promotion code field has a maximum of 191 characters',
        provinceRequired: 'Select a province',
        refCatastralMax: 'The catastral reference field has a maximum of 191 characters',
        refCatastralRequired: 'Specify a catastral reference',
        requestTypeRequired: 'Select a request type',
        servicerIdMax: 'The servicer id field has a maximum of 191 characters',
        servicerRequired: 'Select a servicer',
        spreadsheetFormat: 'Unsupported format, please compare with sample file',
        spreadsheetRequired: 'Select a file',
    },
    visitData: 'Data for visit',
    visited: 'Visited',
    visitMotive: 'Motive visit',
    visitor: 'Visitor',
    visitorControl: 'Visitor control',
    visitorList: 'Visitor control',
    watchman: 'Watchman',
    watchmanArrivalDate: 'Arrival date',
    watchmanArrivalGeolocation: 'Arrival geolocation',
    watchmanArrivalTime: 'Arrival time',
    watchmanCompletionDate: 'Finished date',
    watchmanCompletionGeolocation: 'Finished geolocation',
    watchmanCompletionTime: 'Finished time',
    watchmanName: 'Watchman',
    watchmanTip: 'Tip',
    yes: 'Yes',
    zone: 'Zone',
    tasks: 'Tasks',
    visit: 'Visit',
    roundIncidentDetails: 'Incident details for checkpoint #',
    loadingRoundIncidentImage: 'Loading incident image...',
    errorDownloadingIncidentImage: 'Error downloading incident image',
    roundTasksDetails: 'Task details for checkpoint #',
    errorFetchingRoundTasks: 'Error retrieving tasks for checkpoint',
    loadingTasks: 'Loading tasks...',
    pleaseTryAgain: 'Please try again, if the issue persist please contact with a responsible.',
    close: 'Close',
}

export const paths = {
    discontinuousSurveillanceServices: '/vigilancia-discontinua',
    evaluations: '/inspecciones',
    events: '/eventos',
    home: '/',
    incidents: '/acudas',
    keyrings: '/llaveros',
    login: '/login',
    registrationRequestForm: '/nueva_solicitud_de_alta',
    registrationRequestUpload: '/subida-solicitudes-alta',
    rounds: '/rondas',
    surveillanceServices: '/vigilancia',
    visitorControl: '/control-de-visitas',
    visits: '/visits',
}

export const formats = {
    EXCEL: 'EXCEL',
    PDF: 'PDF',
}

export const columnNames = {
    area: 'area',
    arrived_date: 'arrived_date',
    box_nfc: 'box_nfc',
    card_number: 'card_number',
    center: 'center',
    company_name: 'company_name',
    compartment_code: 'compartment_code',
    customer_contract_service: 'customer_contract_service',
    customer_contract: 'customer_contract',
    date: 'date',
    description: 'description',
    dni: 'dni',
    event: 'event',
    finished_date: 'finished_date',
    image: 'image',
    incident_id: 'incident_id',
    incident: 'incident',
    inspection_code: 'inspection_code',
    inspection_date: 'inspection_date',
    inspection_user: 'inspection_user',
    installation_code: 'installation_code',
    keyring_nfc: 'keyring_nfc',
    keyring_type: 'keyring_type',
    keys_status: 'keys_status',
    name: 'name',
    num_keys: 'num_keys',
    observations: 'observations',
    partition: 'partition',
    person_name: 'person_name',
    point: 'point',
    police_sent: 'police_sent',
    round_name: 'round_name',
    round_name2: 'round_name2',
    subscriber_code: 'subscriber_code',
    timestamp: 'timestamp',
    transmitter: 'transmitter',
    user: 'user',
    visit_motive: 'visit_motive',
    visited_name: 'visited_name',
    visitor_name: 'visitor_name',
    watchman_sent: 'watchman_sent',
    zone: 'zone',
}

export const screenNames = {
    POWERBI_HOME: 'POWERBI_HOME',
}
